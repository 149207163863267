<template>
  <!-- 关注公众号 -->
  <div class="container">
    <van-popup round :closeable="true" v-model="queriesShow">
      <img
        class="img"
        src="../../assets/images/cmp_no mission@3x.png"
        alt=""
      />
      <div class="hint">查询次数用完,完成任务继续查询</div>
     <van-button style="width: 100%" round type="info" @click="$router.push('/task')">确定</van-button>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queriesShow: false, // 关注公众号
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  /deep/ .van-popup--center.van-popup--round {
    box-sizing: border-box;
    width: 90%;
    height: 370px;
    padding: 0 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .img {
      width: 146px;
      height: 158px;
      margin-top: 55px;
    }
    .hint {
      color: #333;
      font-size: 16px;
      margin: 30px 0;
    }
  }
}
</style>